import React from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const GenericPdfDownloader = ({ downloadFileName }) => {
  console.log(downloadFileName);
  const downloadPdfDocument = () => {
    // Capture the visible portion of the page
    // const input = document.documentElement;
    const input = document.querySelector("#invoice");

    // Set the size of the PDF to A4 dimensions (210mm x 297mm)
    const pdfWidth = 210;
    const pdfHeight = 297;

    html2canvas(input, {
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait", // You can use "landscape" for landscape orientation
        unit: "mm",
        format: [pdfWidth, pdfHeight],
      });

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${downloadFileName}.pdf`);
    });
  };

  return (
    <button
      onClick={downloadPdfDocument}
      className="bg-red-600 text-2xl font-bold rounded-xl p-2 text-white"
    >
      Download Invoice PDF
    </button>
  );
};

export default GenericPdfDownloader;
